<template>
  <div :id="id" style="width:100%;height:86px;"></div>
</template>

<script>
export default {
  name: "guageEcharts",
  props:{
    id:{
      type:String,
      default:''
    },
    num:{
      type:Number,
      default:0
    },
  },
  data(){
    return{

    }
  },
  methods:{
    renderChart (id) {
      const charts = this.$echarts.init(document.getElementById(this.id))

      const containerHeight = charts ? charts.offsetHeight : 86; // 默认值

      const option = {
        series: [
          {
            radius: 86,
            startAngle: 180,
            endAngle: 0,
            center: ['50%', '100%'],
            type: 'gauge',
            progress: {
              show: true,
              width: 18,
              itemStyle:{
                color:'#1664FF'
              }
            },
            axisLine: {
              lineStyle: {
                width: 18,
              },
            },
            axisTick: {
              show: false
            },
            splitLine: {
              length: 15,
              lineStyle: {
                width: 2,
                color: '#999'
              },
              show: false
            },
            axisLabel: {
              distance: 25,
              color: '#999',
              fontSize: 20,
              show: false
            },
            anchor: {
              show: false,
              showAbove: true,
              size: 25,
              itemStyle: {
                borderWidth: 0,
                show: false,
              }
            },
            title: {
              show: false,
            },
            pointer: {
              //这个show属性好像有问题，因为在这次开发中，需要去掉指正，我设置false的时候，还是显示指针，估计是BUG吧，我用的echarts-3.2.3；希望改进。最终，我把width属性设置为0，成功搞定！
              show: false,
              //指针长度
              length: '90%',
              width: 0,
            },
            axisPointer:{
              show: false
            },
            detail: {
              valueAnimation: true,
              fontSize: 20,
              offsetCenter: ['0', '-35%'],
              formatter: '{value}%',
              textStyle: {
                fontSize: 24,
                color: '#1664FF'
              },
              show:false
            },
            data: [
              {
                value: this.num
              }
            ]
          }
        ]
      };
      charts.setOption(option)
    }
  },
  mounted() {
    // this.renderChart()
    this.$nextTick(() => {
      this.renderChart();
      window.addEventListener('resize', this.renderChart);
    });
  }


}
</script>

<style scoped>

</style>