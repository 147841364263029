
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import buyAddressApi from "@/common/api/business";
import { Util } from "@/common/util";

@Component
export default class RecommandsList extends Vue {
  // @Prop({ type: [String, Number], required: true }) readonly child!: string | number
  @Prop() tableData!: any;
  @Prop() id!: string;
  @Prop() showDeleteButton!:boolean;
  private buyAddressApi: any = new buyAddressApi();

  private godsdelete(data: any): void {
    console.log(data)
    let gods = "";
    for (let i of this.tableData) {
      if (i.goods_id != data.goods_id) {
        gods += i.goods_id + "=" + i.num + ",";
      }
    }
    this.buyAddressApi.goodsSalecart(
      {
        cart_goods: gods,
        order_id: data.order_id,
        delete_good_ids: data.id,
        desk_id:data.desk_id
      },
      () => {
        this.$emit("delgods");
        Util.showToast("删除成功");
      }
    );
  }
}
